import React, {FC, useEffect} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    // PointElement,
    // LineElement,
    ScriptableContext, Legend, // Filler
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import _ from "lodash";
import moment from 'moment';

interface StatsCardProps {
    label: string
    data: {
        label: string,
        data: string
    }[],
    unit?: string
    isUsd?: boolean
}


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const StatsCard: FC<StatsCardProps> = ({label, data: rawData, unit, isUsd = false}: StatsCardProps) => {

    let data = _.sortBy(rawData, (item) => moment(item.label).format())

    let chartData: {
        labels: string[],
        data: string[]
    } = {
        labels: [],
        data: []
    }

    // @ts-ignore
    const options = {
        responsive: true,
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                }
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
            }
        },
        plugins: {
            subtitle: {
                display: false
            },
            legend: {
                display: false
            },
            title: {
                display: false,
                // text: 'Chart.js Bar Chart',
            },
            tooltip: {
                enabled: true
            }
        },
    };

    if (data) {
        chartData = {
            labels: data.map(x => x.label),
            data: data.map(x => parseFloat(x.data).toFixed(4)),
        }
    }


    // const barThickness = () => {
    //     switch (data?.length) {
    //         case 1:
    //             return 15
    //         case 7:
    //             return 7
    //         case 14:
    //             return 4
    //         case 21:
    //             return 3
    //         case 30:
    //             return 2
    //         default:
    //             return 7
    //     }
    // }

    const preparedData = {
        labels: chartData.labels,
        datasets: [
            {
                data: chartData.data,
                // barThickness: barThickness(),
                // maxBarThickness: 100,
                // backgroundColor: chartData.labels.map((_, i) => i % 2 === 0 ? '#14b498' : '#036d59'),
                borderRadius: {
                    topRight: Number.MAX_VALUE,
                    topLeft: Number.MAX_VALUE,
                },
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 50);
                    gradient.addColorStop(0, "#14b498");
                    gradient.addColorStop(1, "#17897900");
                    return gradient;
                },
                borderColor: '#14b498',

                fill: true,
                lineTension: 0.5,
                borderWidth: 1.9,
                pointHoverRadius: 4,
                pointRadius: 0.7,

            },
        ],
    };

    // _.sumBy(chartData.data, (x: string) => BigInt(x)).toPrecision(4) ;
    return (
        <div className='card card-style-1'>
            <div className=' d-flex justify-content-between'>
                <div className='card-left'>
                    <h3 className='card-title'>{label}</h3>
                    <div
                        className='card-value'>{
                            _.round(
                                _.sum(data.map(x => parseFloat(String(x.data).replace(/,/g, '')))), // Remove commas before parsing
                                isUsd ? 0 : 4 // Apply rounding based on the isUsd flag
                            ).toLocaleString() // Format the number with commas as appropriate
                        }</div>
                    <div className='sub-text'>{unit}</div>

                </div>
                <div className='card-right'>
                    {/*<Bar options={options} data={preparedData}
                          width={120}
                          height={65}/>*/}
                    <Line options={options} data={preparedData}
                    />
                </div>
            </div>
        </div>
    );
}

export default StatsCard;
