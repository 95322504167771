import React, {FC} from 'react';

interface FaqsProps {
}

const Faqs: FC<FaqsProps> = () => {

    return (
        <section className='section-faqs'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="accordion faq-accordion" id="faqsAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#tab-01" aria-expanded="true" aria-controls="tab-01">
                                        What are the statistics on this page?
                                    </button>
                                </h2>
                                <div id="tab-01" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <p>
                                        The statistics on this page is data from the Hydranet DEX. It includes information such as trading volume, amount of trades, acquired trading fees, amount of open state channels, and more. The statistics can be filtered on duration and trading pair. 
                                        </p>
                                        <p>For each trading pair, you can view the number of trades, volume, and fee data separately. Additionally, you can examine general statistics, including fee charges initially, using the "All" option in the filter area.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#tab-02" aria-expanded="false" aria-controls="tab-02">
                                        How often is it updated?
                                    </button>
                                </h2>
                                <div id="tab-02" className="accordion-collapse collapse">
                                    <div className="accordion-body">

The statistics on this page are updated once per day.

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#tab-03" aria-expanded="false" aria-controls="tab-03">
                                       What will the earned Fees be spent on?
                                    </button>
                                </h2>
                                <div id="tab-03" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                    The trading fees earned from the Hydranet DEX will be spent according to Hydranet’s <b><a href="https://hydranet.ai/en/tokenomics" rel="noreferrer" target="_blank">tokenomics</a></b>. Primarily, the fees will be used for the continued development of the Hydranet DEX and for HDN liquidity. 
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#tab-04" aria-expanded="false" aria-controls="tab-01">
                                       Will more pairs be added?
                                    </button>
                                </h2>
                                <div id="tab-04" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>
                                        More pairs will be added to the Hydranet DEX, but it is not determined when. Furthermore, this has to be determined via a DAO vote. 
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#tab-05" aria-expanded="false" aria-controls="tab-02">
                                       What is Rented channel count and Rented balance?
                                    </button>
                                </h2>
                                <div id="tab-05" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                 <p>Users trading on the Hydranet DEX need to rent liquidity from the Hub. The rented liquidity allows the users to receive off-chain payments by increasing their state channel’s inbound liquidity. The Rented channel count displays the total number of channels used for rented liquidity and the Rented balance displays the total amount of rented liquidity expressed in USD. </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faqs;
