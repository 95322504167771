import React from 'react';
import './App.css';
import './icons.css';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./layout/Layout/Layout";
import Dashboard from "./pages/Dashboard/Dashboard";
import Doc from "./pages/Doc/Doc";
import Faqs from "./pages/Faqs/Faqs";
import Home from "./pages/Home/Home";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path='doc' element={<Doc/>}/>
                    <Route path='faqs' element={<Faqs/>}/>
                </Route>
                <Route path='/:pair' element={<Layout/>}>
                    <Route index element={<Dashboard/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
