import React, {FC} from 'react';

import {Chart as ChartJS, ArcElement, Tooltip} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import _ from "lodash";
import {AssetStore} from "../../stores/AssetStore";

ChartJS.register(ArcElement, Tooltip);

interface DoughnutChartProps {
    label: string,
    data?: any
    length?: number,
    isUsd?: boolean
}

const DoughnutChart: FC<DoughnutChartProps> = ({label, data, length, isUsd = false}: DoughnutChartProps) => {

    const colors = ['#1899af', '#c016fa', '#43b222', '#2d70dd', '#c54114', '#5b5dff'];
    // const colors = dataValues.map(_ => faker.color.rgb());
    const assetStore = AssetStore();

    const config = {
        labels: data.map((x: any) => x.label),
        datasets: [
            {
                label: '',
                data: data.map((x: any) => _.round(x.data, (isUsd ? 0 : 4))),
                backgroundColor: colors,
                borderColor: colors,
                hoverOffset: 0,
                borderWidth: 1,
                cutout: 40,
                spacing: 0,
                // circumference: 360,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            subtitle: {
                display: false
            },
            legend: {
                display: false
            },
            title: {
                display: false,
                // text: 'Chart.js Bar Chart',
            },
            tooltip: {
                enabled: true
            }
        },
    }

    return (
        <div className='card card-style-1'>
            <div className=' d-flex justify-content-between'>
                <div className='card-left'>
                    <h3 className='card-title'>{label}</h3>
                    <div className='card-value'>
                        {
                            data &&
                            <span>{(_.round(_.sum(data.map((x: any) => parseFloat(x.data))), (isUsd ? 0 : 4))).toLocaleString()}</span>
                        }
                    </div>
                    <div className='sub-text'>#Assets {length || assetStore.assets.length}</div>
                </div>
                <div className='chart-right'>
                    <Doughnut data={config} options={options}/>
                </div>
            </div>
        </div>
    )
};

export default DoughnutChart;
