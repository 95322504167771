import {create} from "zustand";
// import _ from "lodash";
import axios from "axios";
// import axios, {AxiosResponse} from "axios";

export interface IChannelStore {
    channels: any[],
    loading: boolean,
    fetch: (assets: string[], refresh?: boolean) => Promise<any>
    fetchByAsset: (asset: string) => any
    fetchByPair: (assets: string[]) => any[]
}

export const ChannelStore = create<IChannelStore>()((set, get) => ({
    channels: [],
    loading: false,
    fetch: async (assets: string[], refresh?: boolean): Promise<any> => {
        const {channels} = get();
        if (channels.length <= 0 || refresh) {
            try {
                let responses: any[] = await Promise.all(assets.map(asset => {
                    return new Promise((resolve, reject) => {
                        axios.get(`${process.env.REACT_APP_BACKEND_URL}/channels/${asset.toLowerCase()}`).then(response => {
                            if (response.status === 200) {
                                resolve({
                                    asset: asset,
                                    channel: response.data
                                });
                            } else {
                                resolve(null)
                            }
                        }).catch(error => {
                            resolve(null);
                        })
                    })
                }));
                responses = responses.filter(response => response);
                set({channels: responses, loading: false})
                /*let result = {
                    name: asset,
                    chain_id: 1,
                    decimals: _.random(9),
                    number_of_channels: _.random(1, 50),
                    volume_in_channels: _.random(400000, 499999)
                };
                channels.set(asset, result);*/
                // set({channels: channels})
                return Promise.resolve(responses)
            } catch (error) {
                return Promise.reject('Failed request' + error)
            }
        }
        return Promise.reject('Failed request')
    },
    fetchByAsset: (asset: string): any => {
        const {channels} = get();
        return channels.find(x => x.asset === asset)
    },
    fetchByPair: (assets: string[]): any[] => {
        const {channels} = get();
        return channels.filter(x => assets.includes(x.asset))
    }
}))
