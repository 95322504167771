import React, {FC, useEffect, useState} from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    ScriptableContext,
    Legend, Filler,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import _ from "lodash";
import {IPriceStore, PriceStore} from "../../stores/PriceStore";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

interface LineChartProps {
    label: string,
    data: {
        label: string,
        data: number
    }[],
    unit?: string
    showUSD?: boolean,
    isUsd?: boolean
}

const LineChart: FC<LineChartProps> = ({label, data, unit, showUSD, isUsd = false}: LineChartProps) => {

    const priceStore: IPriceStore = PriceStore();

    const options = {
        responsive: true,
        plugins: {
            subtitle: {
                display: false
            },
            legend: {
                display: false
            },
            title: {
                display: false,
                // text: 'Chart.js Bar Chart',
            },
            tooltip: {
                enabled: true
            }
        },
    };

    const charData = {
        labels: data.map(x => x.label),
        datasets: [
            {
                label: label,
                data: data.map(x => x.data),
                borderColor: '#0B6FC9',
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, "#1387F1");
                    gradient.addColorStop(1, "rgba(64,51,57,0)");
                    return gradient;
                },
                fill: true,
                lineTension: 0.3,
                borderWidth: 2,
                pointHoverRadius: 5,
                pointRadius: 3,
            },
        ],

    };

    const [usdPrice, setUsdPrice] = useState(0)

    useEffect(() => {
        if (showUSD && unit) {
            // let sum = _.round(_.sum(data.map(x => parseFloat(x.data.toString()))), (isUsd ? 0 : 4))
            let sum = _.sum(data.map(x => parseFloat(x.data.toString())));
            let price = priceStore.fetchByAsset(unit);
            if (price) {
                let usdRate = price.prices.usd;
                setUsdPrice(sum * usdRate);
            }
        }
    }, [data]);

    return (
        <div className='card card-style-2'>
            <div className='card-top d-flex justify-content-between align-items-center'>
                <h3 className='card-title'>{label}</h3>
                <div className='card-value'>
                    {
                        showUSD ?
                            `${_.round(usdPrice, (isUsd ? 0 : 4)).toLocaleString()} $` :
                            `${_.round(_.sum(data.map(x => parseFloat(x.data.toString()))), (isUsd ? 0 : 4)).toLocaleString()} ${unit}`
                    }
                </div>
            </div>
            <div className='chart'>
                <Line options={options} data={charData}/>
            </div>
        </div>
    );
}

export default LineChart;
