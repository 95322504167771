import React, {FC, useEffect} from 'react';
import {Outlet, useParams} from "react-router-dom";
import Header from "../../components/Header/Header";
import {AssetStore, IAssetStore} from "../../stores/AssetStore";
import {ITradingPairStore, TradingPairStore} from "../../stores/TradingPairStore";
import {ChannelStore, IChannelStore} from "../../stores/ChannelStore";
// import {debug} from "util";
import {IPriceStore, PriceStore} from "../../stores/PriceStore";


interface LayoutProps {
}

const Layout: FC<LayoutProps> = () => {

    let {pair} = useParams();

    const assetStore: IAssetStore = AssetStore();
    const priceStore: IPriceStore = PriceStore();
    const tradingPairStore: ITradingPairStore = TradingPairStore();
    const channelStore: IChannelStore = ChannelStore();

    useEffect(() => {
        assetStore.fetch().then(assets => {
            priceStore.fetch(assets)
            channelStore.fetch(assets)
        })
        tradingPairStore.fetch(pair || 'ETH_BTC')
    }, [])

    return (
        <>
            {
                tradingPairStore.loading ?
                    <>Loading</> :
                    <>
                        <Header></Header>
                        <main>
                            <Outlet/>
                        </main>
                    </>
            }
        </>
    )
};

export default Layout;
