import React, {FC, useEffect, useState} from 'react';
import DoughnutChart from "../../components/DoughnutChart/DoughnutChart";
import LineChart from "../../components/LineChart/LineChart";
import StatsCard from '../../components/StatsCard/StatsCard';
import {ChannelStore} from "../../stores/ChannelStore";
import {TradingPairStore} from "../../stores/TradingPairStore";
import {useParams} from "react-router-dom";
// import _ from "lodash";
import {AssetStore} from "../../stores/AssetStore";
import {useSearchParams} from "react-router-dom";
import {IPriceStore, PriceStore} from "../../stores/PriceStore";
import moment from "moment/moment";

interface DashboardProps {
}

const Dashboard: FC<DashboardProps> = () => {
    let {pair} = useParams();

    let [searchParams] = useSearchParams();

    const [filterOption, setFilterOption] = useState<number>(30);
    const [firstAsset, setFirstAsset] = useState<any[]>([]);
    const [firstAssetPrice, setFirstAssetPrice] = useState<any>();
    const [secondAssetPrice, setSecondAssetPrice] = useState<any>();
    const [secondAsset, setSecondAsset] = useState<any[]>([]);
    const [tradingPairData, setTradingPairData] = useState<any[]>([]);

    const channelStore = ChannelStore();
    const tradingPairStore = TradingPairStore();
    const assetStore = AssetStore();
    const priceStore: IPriceStore = PriceStore();

    useEffect(() => {
        let filter = searchParams.get('filter');
        if (filter) {
            setFilterOption(parseInt(filter))
        }
    }, []);

    useEffect(() => {
        if (pair && priceStore.prices.length > 0) {
            tradingPairStore.fetchDays(pair, filterOption).then(data => {
                setTradingPairData(data);
            })
            assetStore.fetchRewards(pair.split('_')[0], filterOption).then(data => {
                setFirstAsset(data);
            })
            assetStore.fetchRewards(pair.split('_')[1], filterOption).then(data => {
                setSecondAsset(data);
            })
            let firstAssetPrice = priceStore.fetchByAsset(pair.split('_')[0]);
            setFirstAssetPrice(firstAssetPrice);


            let secondAssetPrice = priceStore.fetchByAsset(pair.split('_')[1]);
            setSecondAssetPrice(secondAssetPrice);

        }
    }, [tradingPairStore.selectedPair, filterOption, assetStore, pair, tradingPairStore, priceStore.prices])

    return (
        <>
            {/*region Filter Section*/}
            <section className='section section-filter'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        {
                            tradingPairStore.pairs.length > 0 &&
                            <div className='col-lg-auto col-md-5'>
                                <div className='selection'>
                                    <span className='select-title'>Select Pair:</span>
                                    <div className='select-column'>
                                        <select className="form-select pair-select" aria-label="Select Pair"
                                                onChange={(event) => {
                                                    if (event.target.value !== 'all') {
                                                        if (event.target.value !== 'all') {
                                                            window.location.replace(`${window.location.origin}/${event.target.value}`);
                                                            tradingPairStore.fetch(event.target.value, true);
                                                        }
                                                        tradingPairStore.fetch(event.target.value, true);
                                                    } else {
                                                        window.location.replace(`${window.location.origin}`);
                                                    }
                                                }}>
                                            <option selected={pair === 'all'} value={'all'}>All</option>
                                            {
                                                tradingPairStore.pairs.map((data, i) => (
                                                    <option key={i} value={data}
                                                            selected={data === pair}>{data.split('_').join('/')}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='col-lg-auto col-md-7'>
                            <div className='date-filter'>
                                <div className='filter-title'>Filter:</div>
                                <ul className='filter-options'>
                                    {/* <li>
                                        <button className={`filter-button${filterOption === 1 ? ' active' : ''}`}
                                                onClick={() => {
                                                    setFilterOption(1)
                                                }}>24 Hours
                                        </button>
                                    </li>*/}
                                    <li>
                                        <button className={`filter-button${filterOption === 7 ? ' active' : ''}`}
                                                onClick={() => {
                                                    setFilterOption(7)
                                                }}>7 Days
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`filter-button${filterOption === 14 ? ' active' : ''}`}
                                                onClick={() => {
                                                    setFilterOption(14)
                                                }}>2 Weeks
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`filter-button${filterOption === 21 ? ' active' : ''}`}
                                                onClick={() => {
                                                    setFilterOption(21)
                                                }}>3 Weeks
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`filter-button${filterOption === 30 ? ' active' : ''}`}
                                                onClick={() => {
                                                    setFilterOption(30)
                                                }}>1 Month
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className={`filter-button${filterOption === Math.round(moment.duration(moment().diff(moment("2023-09-23"))).asDays()) ? ' active' : ''}`}
                                            onClick={() => {
                                                setFilterOption(Math.round(moment.duration(moment().diff(moment("2023-09-23"))).asDays()))
                                            }}>MAX
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*endregion*/}
            {/*region Trading Pairs StatCards*/}
            {
                tradingPairData.length > 0 &&
                <section className='section section-cards mt-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-6 col-md-6'>
                                <StatsCard label={'Volume of Trades'} data={tradingPairData.map(pair => ({
                                    label: pair.day,
                                    data: pair.volume
                                }))}
                                           isUsd={true}
                                           unit={pair?.split('_').join(' - ')}></StatsCard>
                            </div>
                            <div className='col-xl-3 col-lg-6 col-md-6'>
                                <StatsCard label={'Number of Trades'} data={tradingPairData.map(pair => ({
                                    label: pair.day,
                                    data: pair.trades
                                }))}
                                           isUsd={true}
                                           unit={pair?.split('_').join(' - ')}></StatsCard>
                            </div>
                            <div className='col-xl-3 col-lg-6 col-md-6'>
                                <StatsCard label={`Fees Earned ${pair?.split('_')[0]}`} data={firstAsset.map(x => ({
                                    label: x.day,
                                    data: x.fees_earned
                                }))}
                                           isUsd={true}
                                           unit={pair?.split('_')[0]}></StatsCard>
                            </div>
                            <div className='col-xl-3 col-lg-6 col-md-6'>
                                <StatsCard label={`Fees Earned ${pair?.split('_')[1]}`} data={secondAsset.map(x => ({
                                    label: x.day,
                                    data: x.fees_earned
                                }))}
                                           isUsd={true}
                                           unit={pair?.split('_')[1]}></StatsCard>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/*endregion*/}
            {/*region Channels Doughnut Chart Section*/}
            {
                channelStore.channels.length > 0 && pair &&
                <section className='section section-cards-2 mt-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <DoughnutChart label={'Number of channels'}
                                               length={2}
                                               isUsd={true}
                                               data={channelStore.fetchByPair(pair.split('_')).map(x => ({
                                                   label: x.asset,
                                                   data: x.channel.count
                                               }))}></DoughnutChart>
                            </div>
                            <div className='col-lg-6'>
                                <DoughnutChart label={'Rented Balance In Channels'}
                                               length={2}
                                               isUsd={true}
                                               data={channelStore.fetchByPair(pair.split('_')).map(x => ({
                                                   label: x.asset,
                                                   data: x.channel.rented_balance
                                               }))}></DoughnutChart>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/*endregion*/}
            {/*region Individual Assets Line Chart Section*/}
            <section className='section section-cards-4 mt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <LineChart label={`Fee Earned - ${pair?.split('_')[0]}`}
                                       data={firstAsset.map(x => ({
                                           label: x.day,
                                           data: x.fees_earned
                                       }))}
                                       isUsd={true}
                                       unit={pair?.split('_')[0]}
                            ></LineChart>
                        </div>
                        <div className='col-lg-6'>
                            <LineChart label={`Fee Earned - ${pair?.split('_')[1]}`}
                                       data={secondAsset.map(x => ({
                                           label: x.day,
                                           data: x.fees_earned
                                       }))}
                                       isUsd={true}
                                       unit={pair?.split('_')[1]}
                            ></LineChart>
                        </div>
                    </div>
                </div>
            </section>
            {/*endregion*/}
            <section className='section section-cards-3 mt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            {
                                firstAssetPrice &&
                                <LineChart label={`Fee Earned - ${pair?.split('_')[0]} | USD`}
                                           data={firstAsset.map(x => ({
                                               label: x.day,
                                               data: (parseFloat(x.fees_earned) * parseFloat(firstAssetPrice.prices.usd))
                                           }))}
                                           isUsd={true}
                                           unit={'USD'}
                                ></LineChart>
                            }
                        </div>
                        <div className='col-lg-6'>
                            {
                                secondAssetPrice &&
                                <LineChart label={`Fee Earned - ${pair?.split('_')[1]} | USD`}
                                           data={secondAsset.map(x => ({
                                               label: x.day,
                                               data: (parseFloat(x.fees_earned) * parseFloat(secondAssetPrice.prices.usd))
                                           }))}
                                           isUsd={true}
                                           unit={'USD'}
                                ></LineChart>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Dashboard;
