import React, { FC, useEffect, useState } from 'react';
import { ChannelStore } from "../../stores/ChannelStore";
import { TradingPairStore } from "../../stores/TradingPairStore";
import { AssetStore } from "../../stores/AssetStore";
import { IPriceStore, PriceStore } from "../../stores/PriceStore";
import _ from "lodash";
// import _, {result} from "lodash";
import StatsCard from "../../components/StatsCard/StatsCard";
import DoughnutChart from "../../components/DoughnutChart/DoughnutChart";
import LineChart from "../../components/LineChart/LineChart";
import moment from "moment";


interface DocProps {
}

const Home: FC<DocProps> = () => {

    // useEffect(() => {
    //     window.location.replace('/HDN_AUSDT');
    // }, []);

    const [filterOption, setFilterOption] = useState<number>(30);
    const [tradingPair] = useState('all');
    const [tradingPairData, setTradingPairData] = useState<any[]>([]);
    const [allAssets, setAllAssets] = useState<any>([]);
    const [feesEarnedUSD, setFeesEarnedUSD] = useState<any[]>([])
    const [feesEarnedHDN, setFeesEarnedHDN] = useState<any[]>([])

    const channelStore = ChannelStore();
    const tradingPairStore = TradingPairStore();
    const assetStore = AssetStore();
    const priceStore: IPriceStore = PriceStore();

    useEffect(() => {
        if (priceStore.prices.length > 0 && assetStore.assets.length > 0) {
            tradingPairStore.fetchAllPairsByDays(filterOption).then(data => {
                
                setTradingPairData(data);
            })
            assetStore.fetchAllAssetsByDays(priceStore.prices, filterOption).then((data: any[]) => {
                setAllAssets(data);

                const getAllFeesInAsset = (currency: string) => {
                    return _(data)
                        .map((x: any) => {
                            let assetPrice = priceStore.fetchByAsset(x.asset);
                            if (assetPrice) {
                                return {
                                    ...x,
                                    data: x.data.map((y: any) => ({
                                        ...y,
                                        fees_earned: parseFloat(y.fees_earned) * (assetPrice?.prices?.[currency] || 1)
                                    }))
                                }
                            }
                        })
                        .filter(x => x)
                        .map(x => x.data)
                        .flatten()
                        .groupBy('day')
                        .entries()
                        .map(x => [x[0], ({
                            day: x[0],
                            fees_earned: _.sumBy(x[1], y => parseFloat(y.fees_earned)),
                        })])
                        .map(x => x[1])
                        .value()
                }

                setFeesEarnedUSD(getAllFeesInAsset('usd'))
                let hdn: any | undefined = data.find((x: any) => x.asset.toLowerCase() === 'hdn');
                if (hdn) {
                    setFeesEarnedHDN(hdn.data)
                }
            })
        }
    }, [tradingPairStore.selectedPair, filterOption, assetStore.assets, tradingPairStore, priceStore.prices])


    return (
        <>
            {/*region Filter Section*/}
            <section className='section section-filter'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        {
                            tradingPairStore.pairs.length > 0 &&
                            <div className='col-lg-auto col-md-5'>
                                <div className='selection'>
                                    <span className='select-title'>Select Pair:</span>
                                    <div className='select-column'>
                                        <select className="form-select pair-select" aria-label="Select Pair"
                                            defaultValue={'all'}
                                            onChange={(event) => {
                                                if (event.target.value !== 'all') {
                                                    window.location.replace(`${window.location.origin}/${event.target.value}`);
                                                    tradingPairStore.fetch(event.target.value, true);
                                                }
                                            }}>
                                            <option value={'all'}>All</option>
                                            {
                                                tradingPairStore.pairs.map((data, i) => (
                                                    <option selected={tradingPair === data} key={i}
                                                        value={data}>{data.split('_').join('/')}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                            </div>
                        }
                        <div className="col-lg-auto col-md-7">
                            <div className="date-filter">
                                <div className="filter-title">Filter:</div>
                                <ul className='filter-options'>
                                    <li>
                                        <button className={`filter-button${filterOption === 7 ? ' active' : ''}`}
                                            onClick={() => {
                                                setFilterOption(7)
                                            }}>7 Days
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`filter-button${filterOption === 14 ? ' active' : ''}`}
                                            onClick={() => {
                                                setFilterOption(14)
                                            }}>2 Weeks
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`filter-button${filterOption === 21 ? ' active' : ''}`}
                                            onClick={() => {
                                                setFilterOption(21)
                                            }}>3 Weeks
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`filter-button${filterOption === 30 ? ' active' : ''}`}
                                            onClick={() => {
                                                setFilterOption(30)
                                            }}>1 Month
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`filter-button${filterOption === Math.round(moment.duration(moment().diff(moment("2023-09-23"))).asDays()) ? ' active' : ''}`}
                                            onClick={() => {
                                                setFilterOption(Math.round(moment.duration(moment().diff(moment("2023-09-23"))).asDays()))
                                            }}>MAX
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*endregion*/}
            {/*region Trading Pairs StatCards*/}
            <section className='section section-cards mt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-6 col-md-6'>
                            <StatsCard
                                label={'Volume of Trades USD'}
                              // label={JSON.stringify(priceStore.fetchByAsset("BTC"))}
                                data={tradingPairData.map(pair => ({
                                    label: pair.day,
                                    data: (parseFloat(pair.volume) * parseFloat(priceStore.fetchByAsset(pair.baseAsset)["prices"]["usd"])).toLocaleString()
                                }))}
                                isUsd={true}
                                unit={'All Pairs'}
                            />
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6'>
                            <StatsCard label={'Number of Trades'} data={tradingPairData.map(pair => ({
                                label: pair.day,
                                data: pair.trades
                            }))}
                                isUsd={true}
                                unit={'All Pairs'}></StatsCard>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6'>
                            <StatsCard
                                label={`Fees Earned USD`}
                                data={feesEarnedUSD.map((x: any) => ({
                                    label: x.day,
                                    data: x.fees_earned
                                }))}
                                isUsd={true}
                                unit={'USD'}></StatsCard>
                        </div>

                        <div className='col-xl-3 col-lg-6 col-md-6'>
                            <StatsCard label={`Fees Earned HDN`} data={feesEarnedHDN.map(x => ({
                                label: x.day,
                                data: x.fees_earned
                            }))}
                                isUsd={true}
                                unit={'HDN'}
                            ></StatsCard>
                        </div>
                    </div>
                </div>
            </section>
            {/*endregion*/}
            {/*region Channels Doughnut Chart Section*/}
            {
                channelStore.channels.length > 0 &&
                <section className='section section-cards-2 mt-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <DoughnutChart label={'Number of channels'}
                                    isUsd={true}
                                    data={channelStore.channels.map(x => ({
                                        label: x.asset,
                                        data: x.channel.count
                                    }))}></DoughnutChart>
                            </div>
                            <div className='col-lg-6'>
                                <DoughnutChart label={'Rented Balance In Channels'}
                                    isUsd={true}
                                    data={channelStore.channels.map(x => ({
                                        label: x.asset,
                                        data: x.channel.rented_balance
                                    }))}></DoughnutChart>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/*endregion*/}

            {/*region Individual Assets Line Chart Section*/}
            <section className='section section-cards-3 mt-4'>
                <div className='container'>
                    <div className='row'>
                        {
                            allAssets.map((item: any) => (
                                <div key={item.asset} className='col-lg-6' style={{ marginBottom: "1.5rem" }}>
                                    <LineChart label={`Fee Earned - ${item.asset}`}
                                        isUsd={true}
                                        data={item.data.map((x: any) => ({
                                            label: x.day,
                                            data: x.fees_earned
                                        }))}
                                        showUSD
                                        unit={item.asset}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            {/*endregion*/}
        </>
    );
}

export default Home;
