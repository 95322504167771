import {create} from "zustand";
import axios from "axios";
// import axios, {AxiosResponse} from "axios";


export interface IPriceStore {
    prices: any[],
    loading: boolean,
    fetch: (assets: string[], refresh?: boolean) => Promise<any>
    fetchByAsset: (asset: string) => any
}

export const PriceStore = create<IPriceStore>()((set, get) => ({
    prices: [],
    loading: false,
    fetch: async (assets: string[], refresh?: boolean): Promise<any> => {
        const {prices} = get();
        set({loading: true})
        if (prices.length <= 0 || refresh) {
            try {
                let responses: any[] = await Promise.all(assets.map(asset => {
                    return new Promise((resolve, reject) => {
                        axios.get(`${process.env.REACT_APP_PRICE_URL}/${asset.toLowerCase()}/prices`).then(response => {
                            if (response.status === 200) {
                                resolve({
                                    asset: asset,
                                    prices: response.data
                                });
                            } else {
                                resolve(null)
                            }
                        }).catch(error => {
                            resolve(null);
                        })
                    })
                }));
                responses = responses.filter(response => response);
                set({prices: responses, loading: false})           
                return Promise.resolve(responses)
            } catch (error) {
                return Promise.reject('Failed request' + error)
            }
        }
        return Promise.reject('Failed request')
    },
    fetchByAsset: (asset: string): any => {
        const {prices} = get();
        return prices.find(x => x.asset === asset)
    }
}))
