import {create} from "zustand";
import _ from "lodash";
import axios, {AxiosResponse} from "axios";

export interface ITradingPairStore {
    pairs: any[],
    selectedPair: string,
    loading: boolean,
    fetch: (initialPair: string, refresh?: boolean) => Promise<any[]>
    fetchDays: (pair: string, days: number) => Promise<any[]>
    fetchAllPairsByDays: (days: number) => Promise<any[]>
}

export const TradingPairStore = create<ITradingPairStore>()((set, get) => ({
    pairs: [],
    loading: false,
    selectedPair: '',
    fetch: async (initialPair: string, refresh?: boolean): Promise<any[]> => {
        const {pairs} = get();
        set({loading: true})
        if (pairs.length <= 0 || refresh) {
            try {
                let response: AxiosResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/internal/trading-pairs`);
                let result = [];
                if (response.status === 200 && _.isArray(response.data)) {
                    result = response.data;
                }
                set({pairs: result, selectedPair: initialPair, loading: false});
                return Promise.resolve(result)
            } catch (error) {
                set({loading: false});
                return Promise.reject('Failed request' + error)
            }
        }
        return Promise.reject('Failed request')
    },
    fetchDays: async (pair: string, days: number): Promise<any[]> => {
        try {
            /*  let firstUnit = pair.split('_')[0];
              let result = _.range(days).map((i) => moment().subtract(i, 'days').format('YYYY-MM-DD'))
                  .map(date => ({
                      date,
                      number_of_trades: _.random(500, 1000),
                      volume_of_trades: _.random(1, 20), //# unit will be the first of the pair .e.g ETH_BTC unit will be 15 ETH
                      volume_unit: firstUnit
                  }))
              return Promise.resolve(result)*/
            let response: AxiosResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/internal/trading-pairs/${pair}/daily-volume/${days}`);
            let result = [];
            if (response.status === 200 && _.isArray(response.data)) {
                result = response.data;
            }
            return Promise.resolve(result)
        } catch (error) {
            return Promise.reject('Failed request' + error)
        }
    },
    fetchAllPairsByDays: async (days: number): Promise<any[]> => {
        const {pairs, fetchDays} = get();
        try {
            let responses = await Promise.all(pairs.map(pair => fetchDays(pair, days).then(results => results.map(result => ({
                ...result,
                pair: pair // Include pair information in each result
            })))));
    
            let result = _(responses)
                .flatten()
                .groupBy(x => x.day + '_' + x.pair) // Group by day and pair
                .entries()
                .map(([key, value]) => ({
                    day: key.split('_')[0],
                    baseAsset: key.split('_')[1],
                    trades: _.sumBy(value, y => parseFloat(y.trades)),
                    volume: _.sumBy(value, y => parseFloat(y.volume)),
                }))
                .value();
    
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject('Failed request: ' + error);
        }
    }
    
}))
