import {create} from "zustand";
import _ from "lodash";
// import _, {sortBy} from "lodash";
import axios, {AxiosResponse} from "axios";

export interface IAssetStore {
    assets: string[],
    loading: boolean,
    fetch: () => Promise<any[]>,
    fetchRewards: (asset: string, days: number) => Promise<string[]>
    fetchAllAssetsByDays: (prices: any[], days: number) => Promise<string[]>
}

export const AssetStore = create<IAssetStore>()((set, get) => ({
    assets: [],
    loading: false,
    fetch: async (refresh?: boolean): Promise<string[]> => {
        const {assets} = get();
        set({loading: true})
        if (assets.length <= 0 || refresh) {
            try {
                let response: AxiosResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/internal/currencies`);
                let results: any[] = [];
                if (response.status === 200 && _.isArray(response.data)) {
                    results = response.data;
                }
                results = results.filter(x => !['xsn', 'ltc', 'weth'].includes(x.toLowerCase()));
                results = _(results)
                    .map(x => {
                        if (x === 'AUSDT')
                            return [0, x];
                        else if (x === 'HDN')
                            return [1, x];
                        else if (x === 'BTC')
                            return [2, x];
                        else if (x === 'ETH')
                            return [3, x];
                        else if (x === 'USDT')
                            return [4, x];
                        else
                            return [999, x];

                    })
                    .sortBy(x => x[0])
                    .map(x => x[1])
                    .value()
                set({assets: results, loading: false})
                return Promise.resolve(results)
            } catch (error) {
                return Promise.reject('Failed request' + error)
            }
        }
        return Promise.reject('Failed request')
    },
    fetchRewards: async (asset: string, days: number): Promise<any[]> => {
        try {
            // let result = _.range(days).map((i) => moment().subtract(i, 'days').format('YYYY-MM-DD'))
            //     .map(date => ({
            //         date,
            //         fees_earned: _.random(0.1, 1), //# .e.g 0.3 BTC
            //         fees_earned_usd: _.random(10000, 20000)
            //     }))
            // return Promise.resolve(result)
            let response: AxiosResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/internal/currencies/${asset}/daily-fees/${days}`);
            let results: any[] = [];
            if (response.status === 200 && _.isArray(response.data)) {
                results = response.data;
            }
            return Promise.resolve(results)
        } catch (error) {
            return Promise.reject('Failed request' + error)
        }
    },
    fetchAllAssetsByDays: async (prices: any[], days: number): Promise<any[]> => {
        const {assets, fetchRewards} = get();
        try {
            let response = await Promise.all(assets.map(async asset => {
                let resp: any[] = await fetchRewards(asset, days);
                return Promise.resolve({asset, data: resp})
            }))
            return Promise.resolve(response)
        } catch (error) {
            return Promise.reject('Failed request' + error)
        }
    }
}))
