import React, { FC } from 'react';
import styles from './Doc.module.css';

interface DocProps {}

const Doc: FC<DocProps> = () => (
  <div className={styles.Doc}>
    Doc Component
  </div>
);

export default Doc;
