import React, {FC} from 'react';
import logo from '../../assets/logo.svg'
import sun from '../../assets/light-mode.svg'
import {NavLink} from "react-router-dom"
import {useMediaQuery} from 'react-responsive'
// import {ITradingPairStore, TradingPairStore} from "../../stores/TradingPairStore";

const Desktop = ({children}: any) => {
    const isDesktop = useMediaQuery({minWidth: 1180})
    return isDesktop ? children : null
}

const Mobile = ({children}: any) => {
    const isMobile = useMediaQuery({maxWidth: 1179})
    return isMobile ? children : null
}

interface HeaderProps {
}

const Header: FC<HeaderProps> = () => {
    // let {pair} = useParams();
    // const tradingPairStore: ITradingPairStore = TradingPairStore();

    return (
        <header>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-3 col-6'>
                        <div className="d-flex justify-content-start">
                            <NavLink to="/">
                                <img src={logo} alt='logo'></img>
                            </NavLink>
                        </div>
                    </div>
                    <div className='col-lg-9 col-6'>
                        <Desktop>
                            <div className='d-flex justify-content-end align-items-center'>
                                <ul className='main-nav navbar'>
                                    <li className='nav-item'>
                                        <NavLink to="/">STATS</NavLink>
                                    </li>
                                    <li className='nav-item'>
                                        <NavLink to="/faqs">FAQs</NavLink>
                                    </li>
                                    {/* <li className='nav-item'>
                                        <NavLink to="/doc">DOCS</NavLink>
                                    </li> */}
                                </ul>
                                <button className='switch-mode d-none'>
                                    <img src={sun} alt='logo'></img>
                                </button>
                                <a href='https://hydranet.ai/' rel="noreferrer" target="_blank" className='button button-primary ms-3'>BACK HOME</a>
                            </div>
                        </Desktop>
                        <Mobile>
                            <div className='d-flex justify-content-end'>
                                <button className="menu-control" type="button" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">

                                </button>
                            </div>
                        </Mobile>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary d-none" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
                x
            </button>
            <div className="offcanvas offcanvas-start offcanvas-menu" data-bs-scroll="true" tabIndex={-1}
                 id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasMenuLabel">Menu</h5>
                    <button type="button" className="close-button" data-bs-dismiss="offcanvas" aria-label="Close"><i
                        className="fa-regular fa-circle-xmark"></i></button>
                </div>
                <div className="offcanvas-body">

                    <ul className='mobile-nav navbar'>
                        <li className='nav-item'>
                            <NavLink to="/">STATS</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to="faqs">FAQs</NavLink>
                        </li>
                        {/* <li className='nav-item'>
                            <NavLink to="doc">DOCS</NavLink>
                        </li> */}
                    </ul>

                    <a href='https://hydranet.ai' className='button button-secondary w-100 d-block'>BACK HOME</a>
                    <button className='button button-switch w-100 mt-3 d-none'>LIGHT / DARK MODE</button>

                </div>
            </div>
        </header>
    );

}


export default Header;
